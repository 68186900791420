/* Styles go here */
@import url('https://fonts.googleapis.com/css2?family=Gochi+Hand&family=Rubik:wght@300;400;700&display=swap');

:root { 
    --clr-primary: #156e2f;
    --clr-secondary: #1E1E1E;
    --clr-text-light: #F2ECFF;
    --clr-text-dark: #030303;
    --clr-button-bg-1: #0af04f;
    --clr-button-bg-2: #f0f2f1;
    --clr-button-bg-3: #FF7F50;
}

html {
    font-family: "Rubik" !important;
    font-weight: 400;
}

body {
    background: var(--clr-primary);
    background: linear-gradient(var(--clr-primary), var(--clr-secondary));
    color: var(--clr-text-light);

    min-height: 100vh;
    overflow-x: hidden;
    scroll-behavior: smooth;

    font-family: "Rubik";
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

nav {
    height: 15vh;
}

main {
    width: 85%;
    margin-top: 14vh;
    scroll-snap-type: y mandatory;

    /* padding: 3em 1em; */
}

.welcome {
    min-height: 90vh;
    scroll-snap-align: start;
}

.about {
    padding-top: 10vh;
    background: var(--clr-primary);
    min-height: 100vh;
    scroll-snap-align: start;
}

/* TEXT STYLES */
h1 {
    font-family: "Gochi Hand";
    font-weight: 700;
    font-size: clamp(4rem, 10vw, 10rem);
    transform: rotate(-4deg);
    padding-left: .8em;
}

h2 {
    font-family: "Gochi Hand";
    font-weight: 700;
    font-size: clamp(2rem, 5vw, 5rem);
    padding-left: .8em;
}

h3 {
    margin: 1em auto 0;
}

.sub-header {
    font-family: "Gochi Hand";
    font-weight: 500;
    font-size: clamp(1.35rem, 5vw, 4rem);
    transform: rotate(-4deg) translateX(2.5em) translateY(-0.5em);
    padding-left: 0em;
}

.countdown {
    font-family: "Rubik";
    font-weight: 300;
    font-size: clamp(1rem, 4vw, 4rem);
}

.text, ul {
    font-size: clamp(1.2rem, 2vw, 1.15rem);
    font-weight: 400;
    padding: .85em;
}

/* LINK / BUTTON STYLES */
a {
    display: block;
    color: var(--clr-text-light);
    font-weight: 700;
    text-decoration: none;

    transition: all 250ms ease;
}

a:hover {
    color: var(--clr-button-bg-1);
}

.button {
    display: inline-block;
    padding: .75em 1.5em;
    margin: .5em auto;

    background: linear-gradient(to left, var(--clr-button-bg-1), var(--clr-button-bg-2));
    color: var(--clr-text-dark);

    border: none;
    border-radius: .5em;

    text-transform: uppercase;
    text-decoration: none;
    font-weight: 700;

    transition: all 500ms ease;
}

.nav-button {
    margin: .5em 0;
}

.mint-button {
    min-width: 150px;
    max-width: 225px;

    overflow: hidden;
}

.button:hover {
    color: var(--clr-primary);
    box-shadow: 0px 0px 5px var(--clr-button-bg-3),
    0px 0px 15px var(--clr-button-bg-3),
    0px 0px 30px var(--clr-button-bg-3),
    0px 0px 100px var(--clr-button-bg-3);
}

/* IMAGE STYLES */
.App-logo {
    max-width: 30px;
    height: auto;
    margin: 0 10px;
}

.showcase {
    width: 250px;
    height: auto;
}

.philly {
    width: 320px;
    height: auto;
}

.woodstoxx {
    width: 550px;
    height: auto;
}

.crowd {
    width: 825px;
    height: auto;
}

.concert {
    width: 400px;
    height: auto;
}

.band {
    width: 325px;
    height: auto;
}

/* Social Media Icon */
.circle {
    border: 2px solid var(--clr-text-light);
    border-radius: 50%;

    width: 4em;
    height: 4em;

    margin: 0 .75em 2em;
    position: relative;
}

.circle img {
    width: 30px;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* Media Queries */
@media screen and (min-width: 768px) {
    .showcase {
        width: 300px;
    }
}

@media screen and (min-width: 1200px) {
    .showcase {
        width: 400px;
    }
}

@media screen and (min-width: 1400px) {
    .header {
        text-align: center;
    }

    .social-icons {
        justify-content: flex-start;
    }
}